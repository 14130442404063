import { useRouter } from "next/router";
import { ROUTES, EXTERNAL, ACCOUNT_BASE_URL } from "@/config";
import { AuthHelper } from "@/helpers";
import { useMounted } from "@/hooks";
import { Product } from "@/types";
import { capitalize } from "@/utils";
import { Header as UIHeader, NavItemPrimary } from "@narrative-software/narrative-web-ui";

const logoAnchor = {
  href: `/${ROUTES.BASE.SLUG}`,
  label: "Narrative",
};

const getItems = (pathname: string, product?: Product, isAuthenticated?: boolean, ctaHref?: string) => [
  // Products
  {
    href: `/${ROUTES.SELECT.BASE.SLUG}`,
    label: "Select",
    isActive: pathname.startsWith(`/${ROUTES.SELECT.BASE.SLUG}`),
  },
  {
    href: `/${ROUTES.EDIT.BASE.SLUG}`,
    label: "Edit",
    isActive: pathname.startsWith(`/${ROUTES.EDIT.BASE.SLUG}`),
  },
  {
    href: `/${ROUTES.PUBLISH.BASE.SLUG}`,
    label: "Publish",
    isActive: pathname.startsWith(`/${ROUTES.PUBLISH.BASE.SLUG}`),
  },
  // Select
  ...(product === "select"
    ? [
        {
          href: `/${ROUTES.SELECT.BASE.SLUG}`,
          label: "Overview",
          isActive: pathname === `/${ROUTES.SELECT.BASE.SLUG}`,
        },
        {
          href: `/${ROUTES.SELECT.PRICING.SLUG}`,
          label: "Pricing",
          isActive: pathname === `/${ROUTES.SELECT.PRICING.SLUG}`,
        },
        {
          href: "",
          label: "Resources",
          isActive: pathname.startsWith(`/${ROUTES.SELECT.LESSONS.SLUG}`),
          dropdownItems: [
            {
              href: `/${ROUTES.SELECT.LESSONS.SLUG}`,
              label: "Learn To Use Select",
              isActive: pathname.startsWith(`/${ROUTES.SELECT.LESSONS.SLUG}`),
            },
            {
              href: `/${ROUTES.CHANGELOG.SLUG}`,
              label: "What’s New In Select",
              isActive: pathname === `/${ROUTES.CHANGELOG.SLUG}`,
            },
            {
              href: EXTERNAL.SELECT.SUPPORT.HREF,
              label: "Help & Support",
              isExternal: true,
              isNewTab: true,
              hideMobile: true,
            },
            {
              href: `/${ROUTES.BLOG.SLUG}`,
              label: "Blog",
            },
          ],
        },
      ]
    : product === "edit"
      ? [
          {
            href: `/${ROUTES.EDIT.BASE.SLUG}`,
            label: "Overview",
            isActive: pathname === `/${ROUTES.EDIT.BASE.SLUG}`,
          },
          {
            href: `/${ROUTES.EDIT.PRICING.SLUG}`,
            label: "Pricing",
            isActive: pathname === `/${ROUTES.EDIT.PRICING.SLUG}`,
          },
          {
            href: "",
            label: "Resources",
            dropdownItems: [
              {
                href: `/${ROUTES.CHANGELOG.SLUG}`,
                label: "What’s New In Edit",
                isActive: pathname === `/${ROUTES.CHANGELOG.SLUG}`,
              },
              {
                href: EXTERNAL.EDIT.SUPPORT.HREF,
                label: "Help & Support",
                isExternal: true,
                isNewTab: true,
                hideMobile: true,
              },
              {
                href: `/${ROUTES.BLOG.SLUG}`,
                label: "Blog",
              },
            ],
          },
        ]
      : // Publish
        product === "publish"
        ? [
            {
              href: `/${ROUTES.PUBLISH.BASE.SLUG}`,
              label: "Overview",
              isActive: pathname === `/${ROUTES.PUBLISH.BASE.SLUG}`,
            },
            {
              href: `/${ROUTES.PUBLISH.PRICING.SLUG}`,
              label: "Pricing",
              isActive: pathname === `/${ROUTES.PUBLISH.PRICING.SLUG}`,
            },
            {
              href: "",
              label: "Resources",
              isActive: pathname.startsWith(`/${ROUTES.PUBLISH.LESSONS.SLUG}`),
              dropdownItems: [
                {
                  href: `/${ROUTES.PUBLISH.LESSONS.SLUG}`,
                  label: "Learn To Use Publish",
                  isActive: pathname.startsWith(`/${ROUTES.PUBLISH.LESSONS.SLUG}`),
                },
                {
                  href: EXTERNAL.PUBLISH.SUPPORT.HREF,
                  label: "Help & Support",
                  isExternal: true,
                  isNewTab: true,
                  hideMobile: true,
                },
                {
                  href: `/${ROUTES.BLOG.SLUG}`,
                  label: "Blog",
                },
                {
                  href: `/${ROUTES.AWARDS.BASE.SLUG}`,
                  label: "Awards",
                },
              ],
            },
          ]
        : // Rest
          [
            {
              href: EXTERNAL.SUPPORT.HREF,
              label: "Support",
              isExternal: true,
              isNewTab: true,
              hideMobile: true,
            },
            {
              href: `/${ROUTES.BLOG.SLUG}`,
              label: "Blog",
              isActive: pathname === `/${ROUTES.BLOG.SLUG}`,
            },
            {
              href: "",
              label: "Company",
              isActive:
                pathname === `/${ROUTES.ABOUT.SLUG}` ||
                pathname === `/${ROUTES.CAREERS.SLUG}` ||
                pathname === `/${ROUTES.PARTNERSHIPS.SLUG}`,
              dropdownItems: [
                {
                  href: `/${ROUTES.ABOUT.SLUG}`,
                  label: "About Us",
                  isActive: pathname === `/${ROUTES.ABOUT.SLUG}`,
                },
                {
                  href: `/${ROUTES.CAREERS.SLUG}`,
                  label: "Careers",
                  isActive: pathname === `/${ROUTES.CAREERS.SLUG}`,
                },
                {
                  href: `/${ROUTES.PARTNERSHIPS.SLUG}`,
                  label: "Partnerships",
                  isActive: pathname === `/${ROUTES.PARTNERSHIPS.SLUG}`,
                },
              ],
            },
          ]),

  // Sign in / Account
  ...(isAuthenticated
    ? [{ href: EXTERNAL.BASE.HREF, label: "My Account", isExternal: true }]
    : [
        {
          href: EXTERNAL.SIGN_IN.HREF,
          label: "Sign in",
          isExternal: true,
        },
        (pathname !== "/" && {
          href: ctaHref
            ? ctaHref.replace("<account-site>", ACCOUNT_BASE_URL as string)
            : product === "select" || product === "edit"
              ? EXTERNAL.SELECT.GET_STARTED.HREF
              : product === "publish"
                ? EXTERNAL.PUBLISH.GET_STARTED.HREF
                : EXTERNAL.GET_STARTED.HREF,
          label: `Get ${product ? capitalize(product) : "started"} free`,
          isButton: true,
          isExternal: true,
        }) as NavItemPrimary,
      ].filter(Boolean)),
];

const mobileSecondaryItems = [
  {
    href: EXTERNAL.SUPPORT.HREF,
    label: "Support",
    isExternal: true,
    isNewTab: true,
  },
  { href: `/${ROUTES.ABOUT.SLUG}`, label: "About Us" },
  { href: `/${ROUTES.CAREERS.SLUG}`, label: "Careers" },
  { href: `/${ROUTES.BLOG.SLUG}`, label: "Blog" },
  { href: `/${ROUTES.PARTNERSHIPS.SLUG}`, label: "Partnerships" },
];

const mobileLegalItems = [
  { href: `/${ROUTES.TERMS.SLUG}`, label: "Terms & Conditions" },
  { href: `/${ROUTES.PRIVACY.SLUG}`, label: "Privacy Policy" },
];

const Header: React.FC<{ product?: Product; ctaHref?: string }> = ({ product, ctaHref }) => {
  const { pathname } = useRouter();
  const isMounted = useMounted();
  const isAuthenticated = isMounted && AuthHelper.isAuthToken();

  const items = getItems(pathname, product, isAuthenticated, ctaHref);

  return (
    <UIHeader
      {...{ logoAnchor, items, mobileSecondaryItems, mobileLegalItems }}
      activePath={pathname}
      className="z-20"
    />
  );
};

export default Header;

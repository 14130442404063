import classNames from "classnames";
import { Container, NavItem } from "@/components";
import { ROUTES } from "@/config";
import { Footer as UIFooter } from "@narrative-software/narrative-web-ui";

const legal = [
  { href: `/${ROUTES.TERMS.SLUG}`, label: "Terms & conditions" },
  { href: `/${ROUTES.PRIVACY.SLUG}`, label: "Privacy policy" },
  { href: `/${ROUTES.AWARDS.TERMS.SLUG}`, label: "Awards terms & conditions" },
];

type Props = {
  items: NavItem[];
};

const AwardsFooter: React.FC<Props> = ({ items }) => {
  const navItems = [
    ...items,
    {
      href: `/${ROUTES.AWARDS.ABOUT.SLUG}#photography-awards-2022-about-faqs`,
      label: "FAQs",
    },
    {
      href: `/${ROUTES.BASE.SLUG}`,
      label: "Narrative Website",
    },
  ];

  return (
    <div
      className={classNames(
        "relative bg-no-repeat mt-4 md:mt-10 lg:mt-18 xl:mt-20",
        "bg-[length:680px_307px]", // size
        "bg-[position:calc(100%+23rem)_bottom]", // position
      )}
    >
      <Container>
        <div className="border-t-4 border-white">
          <UIFooter items={navItems} legal={legal} hideBorder transparent />
        </div>
      </Container>
    </div>
  );
};

export default AwardsFooter;

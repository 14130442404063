import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import Link from "next/link";
import { Container, HtmlToElement, ArrowAnchorButton, AwardsSection as Section } from "@/components";
import { TextMediaItemFragmentType } from "@/modules";
import { createHref } from "@/utils";

const backgroundStyle = {
  backgroundImage: `
    url("/images/awards/texture-bg@2x.webp"),
    url("/images/awards/lens-flare-prizes.webp")
  `,
  backgroundPosition: `
    0% 0%,
    center
  `,
  backgroundSize: `
    100px 100px,
    1169px 1092px
  `,
  backgroundRepeat: `
    repeat,
    no-repeat
  `,
};

interface Props extends TextMediaItemFragmentType {
  addLensFlare?: boolean;
}

const TextImageSection: React.FC<Props> = ({ heading, content, image, imageAlignment, callToAction, addLensFlare }) => {
  const button = callToAction[0];
  return (
    <Section
      customPadding={addLensFlare}
      style={addLensFlare ? backgroundStyle : undefined}
      className={classNames({
        relative: !addLensFlare,
        "py-32 -my-26 md:py-50 md:-my-40 lg:py-75 lg:-my-55 bg-black": addLensFlare,
      })}
    >
      <Container>
        <div
          className={classNames("flex flex-col gap-10 lg:items-center lg:gap-25", {
            "lg:flex-row": imageAlignment === "left",
            "lg:flex-row-reverse": imageAlignment === "right",
          })}
        >
          <div className="md:w-3/4 lg:w-3/5">{image && <DatoImage data={{ ...image.responsiveImage }} />}</div>
          <div className="lg:w-2/5">
            {/* <HorizontalRule className="mb-6" /> */}
            <HtmlToElement element="h2" className="text-40 leading-tighter underline-offset-4 mb-5 md:mb-8">
              {heading}
            </HtmlToElement>
            <HtmlToElement className="prose">{content}</HtmlToElement>
            {button && (
              <Link href={createHref(button.page) + (button.anchorId ? `/${button.anchorId}` : "")} passHref>
                <ArrowAnchorButton variant="tertiary" className="mt-7">
                  {button.label}
                </ArrowAnchorButton>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TextImageSection;
